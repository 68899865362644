import {Component} from '@angular/core';
import {TeamMember} from "../../../types/teamMember.interface";
import {Router} from "@angular/router";

@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent {

    teamMembers: TeamMember[] = [
        <TeamMember>({
            img: "/assets/photos/lukasz_photo.png",
            name: "Lukasz Kowalczyk",
            position: "CEO, AI Specialist",
            linkedin: "https://cy.linkedin.com/in/lukasz-kowalczyk-44161a224",
            gridPosition: "first"
        }),
           <TeamMember>({
                    img: "/assets/photos/karolina_photo.png",
                    name: "Karolina Kowalczyk",
                    position: "Financial Specialist",
                    linkedin: "",
                    gridPosition: "second"

                }),
        <TeamMember>({
            img: "/assets/photos/olek_photo.png",
            name: "Aleksander Zerelik",
            position: "Senior Developer",
            linkedin: "https://pl.linkedin.com/in/aleksander-%C5%BCerelik-683a29204",
            gridPosition: "third"
        }),

        <TeamMember>({
            img: "/assets/photos/wojtek_photo.png",
            name: "Wojtek Lukaszuk",
            position: "Java Developer",
            linkedin: "https://pl.linkedin.com/in/wojciech-%C5%82ukaszuk-7381b41aa",
            gridPosition: "fourth"
        }),
        <TeamMember>({
            img: "/assets/photos/konrad_photo.png",
            name: "Konrad Rudnicki",
            position: "Junior Software Developer",
            linkedin: "https://www.linkedin.com/in/konrad-rudnicki-dev",
            gridPosition: "fifth"
        }),
        <TeamMember>({
            img: "/assets/photos/kinga_photo.png",
            name: "Kinga Jasinska",
            position: "Marketing Specialist",
            linkedin: "https://www.linkedin.com/in/kinga-jasińska-a963b1283/",
            gridPosition: "sixth"
        }),
        <TeamMember>({
            img: "/assets/photos/aleksander_photo.png",
            name: "Aleksander Bojda",
            position: "Machine Learning Engineer",
            linkedin: "https://pl.linkedin.com/in/abojda",
            gridPosition: "seventh"
        }),
        <TeamMember>({
            img: "/assets/photos/julia_photo.png",
            name: "Julka Mykhailiuk",
            position: "Marketing Specialist",
            linkedin: "https://pl.linkedin.com/in/julia-mykhailiuk-07229811a",
            gridPosition: "eighth"
        }),

    ]
    protected readonly window = window;

    constructor(private router: Router) {
    }

    scroll(el: string) {
        this.router.navigate([""])
        document.getElementById(el)!.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });
    }
}
